<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form">
      <el-form-item label="标题：">
        <el-input v-model="search.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus"/>添加公告
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="choose"
      >
        <el-table-column prop="id" label="序号"/>
        <el-table-column prop="name" label="公告标题"/>
        <el-table-column prop="createdAt" label="发布日期"/>
        <el-table-column prop="statusname" label="状态"/>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="edit" @click="toDetail(scope.row)" v-if="isDetail">详情</span>
            <span class="edit" @click="toEdit(scope.row)" v-else>编辑</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'NoticeList',
  data() {
    return {
      search: {
        name: ''
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  computed: mapState({
    isDetail: state => {
      return state.isDetail === 'true'
    }
  }),
  mounted() {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '公告列表'
    }
    this.$store.commit('changeRoute', currentPage)
    this.getList()
  },
  methods: {
    async getList() {
      let {no, size} = this.page
      const params = {
        name: this.search.name,
        page_size: size,
        page_no: no,
      }
      const url = 'admin/notice/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        item.statusname = item.status === 1 ? '生效中' : '未生效'
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    add() {
      this.$router.push('/notice/add')
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    choose(row) {
      this.isDetail ? this.toDetail(row) : this.toEdit(row)
    },
    toEdit(row) {
      this.$router.push(`/notice/add?id=${row.id}`)
    },
    toDetail(row) {
      const toPage = {
        link: 'notice/detail',
        name: '公告详情'
      }
      this.$store.commit('changeRoute', toPage)
      this.$router.push('/notice/detail?id=' + row.id)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
